import { ExportExternalAccountTransactionsFormData } from 'modules/bank/hooks/transaction/useExportExternalAccountTransactionsForm'
import { IconEmail } from 'modules/common/components/_icons/icons'
import { TextField } from 'modules/common/components/_ui/FormControl/TextField/TextField'
import { Message } from 'modules/common/components/_ui/Message/Message'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Controller, useFormContext } from 'react-hook-form'
import {
  TransactionMonthOption,
  TransactionMonthSelect,
} from '../TransactionMonthSelect/TransactionMonthSelect'

interface Props {
  monthOptions: TransactionMonthOption[]
}

export const ExportExternalAccountTransactionsFormContent = ({
  monthOptions,
}: Props) => {
  const { t } = useTranslation('bank-components')

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ExportExternalAccountTransactionsFormData>()

  return (
    <div className="flex flex-col text-left">
      <Message
        title={t('exportExternalAccountTransactionsForm.messageTitle')}
        content={t('exportExternalAccountTransactionsForm.messageContent')}
        className="mb-6 py-2 text-sm"
        type="important"
      />
      <p className="mb-3 mt-2 font-semibold uppercase text-info">
        {t('exportExternalAccountTransactionsForm.monthTitle')}
      </p>
      <Controller
        name="month"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <TransactionMonthSelect
            onChange={(newValue) => newValue && onChange(newValue)}
            onBlur={onBlur}
            value={value}
            options={monthOptions}
            maxMenuHeight={128}
            className="mb-4"
          />
        )}
      />
      <p className="mb-1 py-2 font-semibold uppercase text-info">
        {t('exportExternalAccountTransactionsForm.email.title')}
      </p>
      <TextField
        {...register('email')}
        label={t('exportExternalAccountTransactionsForm.email.label')}
        helperText={t('exportExternalAccountTransactionsForm.email.helperText')}
        leftDecorator={<IconEmail />}
        control={control}
        error={errors.email}
        isRequired
      />
    </div>
  )
}

import { AccountApi } from '@blank/api'
import { ExportInternalAccountTransactionsFormContent } from 'modules/bank/components/transaction/ExportInternalAccountTransactionsFormContent/ExportInternalAccountTransactionsFormContent'
import { useExportMonthTransactionsAdvancedFlow } from 'modules/bank/flows/transaction/useExportMonthTransactionsAdvancedFlow'
import { useExportMonthTransactionsSimpleFlow } from 'modules/bank/flows/transaction/useExportMonthTransactionsSimpleFlow'
import {
  EnumExportTransactionsType,
  ExportInternalAccountTransactionsFormData,
  useExportInternalAccountTransactionsForm,
} from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsForm'
import { useExportInternalAccountTransactionsFormSetup } from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsFormSetup'
import { useExportInternalAccountTransactionsFormState } from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsFormState'
import { formatExportTransactionsDate } from 'modules/bank/utils/format/transactions'
import { getMonthOptions } from 'modules/bank/utils/transform/months'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useFormModal } from 'modules/common/modals/useFormModal/useFormModal'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'

export const useExportInternalAccountTransactionsModal = (
  account: AccountApi
) => {
  const { t, i18n } = useTranslation(['bank-components', 'common'])

  const monthOptions = useMemo(
    () => getMonthOptions(account.createdAt),
    [account.createdAt]
  )

  const form = useExportInternalAccountTransactionsForm()
  const {
    formState: { errors },
    watch,
  } = form

  const monthDate = watch('month.value')

  const { validatedStepNumber } =
    useExportInternalAccountTransactionsFormState(errors)
  useExportInternalAccountTransactionsFormSetup(form)

  const { downloadMonthTransactions } = useExportMonthTransactionsSimpleFlow({
    accountId: account.id,
    monthDate,
  })

  const { buildAdvancedMonthTransactionExport } =
    useExportMonthTransactionsAdvancedFlow(account)

  const onSubmit = ({
    email,
    exportType,
    month,
    endMonth,
  }: ExportInternalAccountTransactionsFormData) => {
    if (exportType === EnumExportTransactionsType.SIMPLE) {
      return downloadMonthTransactions()
    } else {
      return buildAdvancedMonthTransactionExport({
        email,
        date: formatExportTransactionsDate(month.value),
        endDate: endMonth
          ? formatExportTransactionsDate(endMonth.value)
          : undefined,
      })
    }
  }

  return useFormModal({
    width: 'medium',
    form,
    content: (
      <FormProvider {...form}>
        <ExportInternalAccountTransactionsFormContent
          monthOptions={monthOptions}
          validatedStepNumber={validatedStepNumber}
        />
      </FormProvider>
    ),
    headerTitle: t('exportInternalAccountTransactionsModal.h1'),
    secondaryButtonText: t('common:cancel'),
    submitButtonText: t('exportInternalAccountTransactionsModal.submitButton'),
    i18n,
    hiddenOnError: true,
    onSubmit,
  })
}

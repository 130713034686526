import { AccountApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'
import { PostAdvancedTransactionsExportPayload } from '../types'

interface Props {
  payload: PostAdvancedTransactionsExportPayload
  account: AccountApi
  accessToken: string
}
export const postAdvancedTransactionsExport = async ({
  payload,
  account,
  accessToken,
}: Props) => {
  const { data } = await apiClient.post<Record<string, never>>(
    `/v1/bservices/${account.corporateId}/accounts/${account.id}/transactions/export/advanced`,
    payload,
    {
      headers: getAuthorizationHeader(accessToken),
    }
  )
  return data
}

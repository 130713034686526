import {
  BeneficiaryApi,
  CreditorAccountApi,
  CreditorAgentApi,
  CreditorApi,
} from '@blank/api'

export interface PatchBeneficiaryPayload {
  creditor: Omit<CreditorApi, 'isMyAccount'>
}

export interface BeneficiaryRow {
  id: string
  name: string
  iban: string
  bic: string
}

export interface BeneficiaryFormData {
  firstName?: string
  lastName: string
  iban: CreditorAccountApi['iban']
  bic: CreditorAgentApi['bicFi']
}

export interface UpdateBeneficiaryFormData {
  name: string
  iban: CreditorAccountApi['iban']
}

export interface BeneficiaryTypeFormData {
  beneficiaryType: EnumBeneficiaryType
}

export enum EnumBeneficiaryType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

type PostCreditorPayload = Omit<CreditorApi, 'firstName' | 'nickName'>

export type PostBeneficiaryPayload = Omit<BeneficiaryApi, 'id' | 'creditor'> & {
  creditor: PostCreditorPayload
}

export interface PatchBeneficiaryPayload {
  creditor: Omit<CreditorApi, 'isMyAccount'>
}

export enum EnumBackendIbanValidationError {
  ACCOUNT_NUMBER_DIGIT_NOT_CORRECT = 'account_number_digit_not_correct',
  IBAN_DIGIT_NOT_CORRECT = 'iban_digit_not_correct',
  IBAN_LENGTH_NOT_CORRECT = 'iban_length_not_correct',
  IBAN_STRUCTURE_NOT_CORRECT = 'iban_structure_not_correct',
  IBAN_CONTAINS_ILLEGAL_CHARACTERS = 'iban_contains_illegal_characters',
  COUNTRY_DO_NOT_SUPPORT_IBAN_STANDART = 'country_do_not_support_iban_standart',
  UNSUPPORTED_SEPA = 'unsupported_sepa',
}

export const isBackendIbanValidationError = (
  error: string
): error is EnumBackendIbanValidationError =>
  error in EnumBackendIbanValidationError

export interface BeneficiaryInformation {
  firstName?: string
  lastName: string
  iban: string
  bic: string
  bankName: string
  address: string
  locality?: string
}

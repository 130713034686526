import { QueryKey } from '@tanstack/react-query'
import { useCallback } from 'react'
import { QueryFunction, QueryOptions } from '../types/queries'
import { useQueryWithAuth } from './useQueryWithAuth'

export type LazyQueryOptions<TQueryFnData, TError, TData = TQueryFnData> = Omit<
  QueryOptions<TQueryFnData, TError, TData>,
  'enabled'
> & {
  onSuccess?: (data: TData | undefined) => void
  onError?: (e: TError) => void
}

export const useLazyQueryWithAuth = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>({
  queryKey,
  queryFn,
  onSuccess,
  onError,
  ...queryOptions
}: {
  queryFn: QueryFunction<TQueryFnData>
  queryKey: TQueryKey
} & LazyQueryOptions<TQueryFnData, TError, TData>) => {
  const {
    refetch,
    isLoading, // Warning: isLoading state is omitted, only isFetching is supported for lazy queries
    ...result
  } = useQueryWithAuth({
    queryKey,
    queryFn,
    enabled: false,
    ...queryOptions,
  })

  const fetchQuery = useCallback(async () => {
    try {
      const { data } = await refetch({ throwOnError: true })
      onSuccess?.(data)
      return data
    } catch (error) {
      onError?.(error as TError)
      throw error
    }
  }, [onError, onSuccess, refetch])

  return { fetchQuery, ...result }
}

import {
  EnumExportTransactionsType,
  ExportInternalAccountTransactionsFormData,
} from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsForm'
import { RadioButton } from 'modules/common/components/_ui/FormControl/RadioButton/RadioButton'
import { VerticalProgressStep } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStep/VerticalProgressStep'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { ComponentProps } from 'react'
import { useFormContext } from 'react-hook-form'

export const ExportInternalAccountTransactionsExportTypeStep = ({
  status,
}: Pick<ComponentProps<typeof VerticalProgressStep>, 'status'>) => {
  const { t } = useTranslation('bank-components')

  const { register } =
    useFormContext<ExportInternalAccountTransactionsFormData>()

  return (
    <VerticalProgressStep
      title={t('exportInternalAccountTransactionsForm.exportTypeTitle')}
      status={status}
    >
      <RadioButton
        primaryLabel={t(
          'exportInternalAccountTransactionsForm.accountStatementLabel'
        )}
        id={EnumExportTransactionsType.SIMPLE}
        value={EnumExportTransactionsType.SIMPLE}
        className="mt-4"
        primaryLabelClassName="font-bold"
        {...register('exportType')}
      />
      <p className="mt-2">
        {t('exportInternalAccountTransactionsForm.accountStatementDesc')}
      </p>
      <RadioButton
        primaryLabel={t(
          'exportInternalAccountTransactionsForm.accountingExportLabel'
        )}
        id={EnumExportTransactionsType.ADVANCED}
        value={EnumExportTransactionsType.ADVANCED}
        className="mt-6"
        primaryLabelClassName="font-bold"
        {...register('exportType')}
      />
      <p className="mt-2">
        {t('exportInternalAccountTransactionsForm.accountingExportDesc')}
      </p>
    </VerticalProgressStep>
  )
}

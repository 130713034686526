import { MandateApi } from '@blank/api'

export enum EnumMandateStatusTabs {
  WAITING = 'waiting',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface MandatesByTabs {
  [EnumMandateStatusTabs.ACTIVE]: MandateApi[]
  [EnumMandateStatusTabs.INACTIVE]: MandateApi[]
  [EnumMandateStatusTabs.WAITING]: MandateApi[]
}

export interface PostEnterpriseMandateData {
  creditorName: string
  customLabel?: string
  mandateId: string
  creditorId?: string
}

import { ExportInternalAccountTransactionsFormData } from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsForm'
import { IconEmail } from 'modules/common/components/_icons/icons'
import { TextField } from 'modules/common/components/_ui/FormControl/TextField/TextField'
import { VerticalProgressStep } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStep/VerticalProgressStep'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { ComponentProps } from 'react'
import { useFormContext } from 'react-hook-form'

export const ExportInternalAccountTransactionsEmailAddressStep = ({
  status,
}: Pick<ComponentProps<typeof VerticalProgressStep>, 'status'>) => {
  const { t } = useTranslation('bank-components')

  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<ExportInternalAccountTransactionsFormData>()

  return (
    <VerticalProgressStep
      status={status}
      title={t('exportInternalAccountTransactionsForm.email.title')}
    >
      <TextField
        {...register('email')}
        label={t('exportInternalAccountTransactionsForm.email.label')}
        helperText={t('exportInternalAccountTransactionsForm.email.helperText')}
        leftDecorator={<IconEmail />}
        control={control}
        error={errors.email}
        isRequired
      />
    </VerticalProgressStep>
  )
}

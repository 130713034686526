import { yupResolver } from '@hookform/resolvers/yup'
import { TransactionMonthOption } from 'modules/bank/components/transaction/TransactionMonthSelect/TransactionMonthSelect'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { rEmail } from 'modules/common/lib/validation/rules'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

export interface ExportExternalAccountTransactionsFormData {
  month: TransactionMonthOption
  email: string
}

interface Props {
  defaultMonth?: TransactionMonthOption
}

export const useExportExternalAccountTransactionsForm = ({
  defaultMonth,
}: Props) => {
  const { t } = useTranslation('bank-components')

  const schema = yup.object().shape({
    month: yup.object().required(),
    email: yup
      .string()
      .required(
        t('exportExternalAccountTransactionsForm.email.errors.required')
      )
      .matches(
        rEmail,
        t('exportExternalAccountTransactionsForm.email.errors.format')
      ),
  })

  return useForm<ExportExternalAccountTransactionsFormData>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { month: defaultMonth },
  })
}

import {
  EnumExportTransactionsType,
  ExportInternalAccountTransactionsFormData,
} from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsForm'
import { VerticalProgressStepper } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStepper'
import { useVerticalProgressStepStatus } from 'modules/common/hooks/useVerticalProgressStepStatus'
import { useFormContext } from 'react-hook-form'
import { TransactionMonthOption } from '../TransactionMonthSelect/TransactionMonthSelect'
import { ExportInternalAccountTransactionsEmailAddressStep } from './ExportInternalAccountTransactionsEmailAddressStep/ExportInternalAccountTransactionsEmailAddressStep'
import { ExportInternalAccountTransactionsExportTypeStep } from './ExportInternalAccountTransactionsExportTypeStep/ExportInternalAccountTransactionsExportTypeStep'
import { ExportInternalAccountTransactionsMonthStep } from './ExportInternalAccountTransactionsMonthStep/ExportInternalAccountTransactionsMonthStep'

interface Props {
  monthOptions: TransactionMonthOption[]
  validatedStepNumber: number
}

export const ExportInternalAccountTransactionsFormContent = ({
  monthOptions,
  validatedStepNumber,
}: Props) => {
  const { watch } = useFormContext<ExportInternalAccountTransactionsFormData>()

  const selectedExportType = watch('exportType')

  const isAdvancedExport =
    selectedExportType === EnumExportTransactionsType.ADVANCED

  const { registerStepStatus } =
    useVerticalProgressStepStatus(validatedStepNumber)

  return (
    <VerticalProgressStepper className="text-left">
      <ExportInternalAccountTransactionsExportTypeStep
        {...registerStepStatus(0)}
      />
      <ExportInternalAccountTransactionsMonthStep
        isAdvancedExport={isAdvancedExport}
        monthOptions={monthOptions}
        {...registerStepStatus(1)}
      />
      {isAdvancedExport && (
        <ExportInternalAccountTransactionsEmailAddressStep
          {...registerStepStatus(2)}
        />
      )}
    </VerticalProgressStepper>
  )
}

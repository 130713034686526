import { mergeClasses } from '@blank/utilities'
import { PropsWithChildren } from 'react'
import { IconCross } from '../../_icons/icons'
import { Banner, BannerProps } from '../Banner/Banner'
import { Button } from '../Button/Button'

interface Props extends BannerProps {
  icon?: React.ReactNode
  onClose?: () => void
}

export const BannerMessage = ({
  type,
  icon,
  className,
  onClose,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Banner
      type={type}
      className={mergeClasses(
        'flex items-center justify-center space-x-2 p-3 text-info',
        className
      )}
    >
      {icon}
      <div>{children}</div>
      {onClose && (
        <Button
          onClick={onClose}
          variant="none"
          icon={<IconCross className="h-6 w-6" />}
        />
      )}
    </Banner>
  )
}

import { yupResolver } from '@hookform/resolvers/yup'
import { TransactionMonthOption } from 'modules/bank/components/transaction/TransactionMonthSelect/TransactionMonthSelect'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { rEmail } from 'modules/common/lib/validation/rules'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

export enum EnumExportTransactionsType {
  SIMPLE = 'SIMPLE',
  ADVANCED = 'ADVANCED',
}

export interface ExportInternalAccountTransactionsFormData {
  month: TransactionMonthOption
  endMonth?: TransactionMonthOption
  exportType: EnumExportTransactionsType
  email: string
}

export const useExportInternalAccountTransactionsForm = () => {
  const { t } = useTranslation('bank-components')

  const schema = yup.object().shape({
    month: yup.object().required().shape({
      value: yup.date().required().defined(),
      label: yup.string(),
    }),
    exportType: yup
      .string()
      .oneOf([
        EnumExportTransactionsType.SIMPLE,
        EnumExportTransactionsType.ADVANCED,
      ])
      .required(),
    // Email validation only if exportType === EnumExportTransactionsType.ADVANCED
    email: yup.string().when('exportType', (exportType, schema) => {
      return exportType === EnumExportTransactionsType.ADVANCED
        ? schema
            .required(
              t('exportInternalAccountTransactionsForm.email.errors.required')
            )
            .matches(
              rEmail,
              t('exportInternalAccountTransactionsForm.email.errors.format')
            )
        : schema
    }),
    endMonth: yup.object().when(['exportType', 'month'], ((
      exportType: string,
      month: TransactionMonthOption,
      schema: any
    ) => {
      return exportType === EnumExportTransactionsType.ADVANCED
        ? schema.required().shape({
            value: yup
              .date()
              .typeError('')
              .required('')
              .min(
                month.value || new Date(0),
                t(
                  'exportInternalAccountTransactionsForm.endMonth.errors.beforeStartMonth'
                )
              ),
            label: yup.string(),
          })
        : schema
    }) as any),
  })

  const form = useForm<ExportInternalAccountTransactionsFormData>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const { trigger, watch } = form

  const month = watch('month.value')

  // Trigger form validation at init to setup stepper
  useEffect(() => {
    trigger()
  }, [trigger, month])

  return form
}

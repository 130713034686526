import { IconCalendar } from 'modules/common/components/_icons/icons'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import ReactSelect, {
  components,
  ControlProps,
  Props as ReactSelectProps,
} from 'react-select'

export interface TransactionMonthOption {
  value: string
  label: string
}

const CustomControl = (props: ControlProps<TransactionMonthOption, false>) => {
  return (
    <components.Control {...props}>
      {props.selectProps.value && <IconCalendar className="mr-2" />}
      {props.children}
    </components.Control>
  )
}

const CustomPlaceholder = ({ title }: { title: string }) => {
  return (
    <div className="flex items-center text-info-600">
      <IconCalendar className="mr-2" />
      <span>{title}</span>
      <span className="text-danger-500">&nbsp;*</span>
    </div>
  )
}

export const TransactionMonthSelect = ({
  className,
  placeholderTitle,
  ...selectProps
}: ReactSelectProps<TransactionMonthOption, false> & {
  placeholderTitle?: string
}) => {
  const { t } = useTranslation('bank-components')

  return (
    <ReactSelect
      {...selectProps}
      className={className}
      classNamePrefix="blnk-react-select"
      inputId="transactionMonth"
      placeholder={
        <CustomPlaceholder
          title={
            placeholderTitle || t('transactionMonthSelect.placeholderSimple')
          }
        />
      }
      components={{
        Control: CustomControl,
      }}
      isSearchable={false}
    />
  )
}

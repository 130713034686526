import { InfiniteData, QueryKey, useInfiniteQuery } from '@tanstack/react-query'
import { InfiniteQueryFunction, InfiniteQueryOptions } from '../types/queries'
import { useFreshToken } from './useFreshToken'

export const useInfiniteQueryWithAuth = <
  TQueryFnData = unknown,
  TError = unknown,
  TPageParam = unknown,
>({
  queryKey,
  queryFn,
  ...queryOptions
}: InfiniteQueryOptions<
  TQueryFnData,
  TError,
  InfiniteData<TQueryFnData>,
  QueryKey,
  TPageParam
> & {
  queryFn: InfiniteQueryFunction<TQueryFnData, QueryKey, TPageParam>
  queryKey: QueryKey
}) => {
  const getFreshToken = useFreshToken()

  return useInfiniteQuery<
    TQueryFnData,
    TError,
    InfiniteData<TQueryFnData>,
    QueryKey,
    TPageParam
  >({
    queryKey,
    queryFn: async (context) => {
      const accessToken = await getFreshToken()

      if (!accessToken) {
        throw new Error(`useQueryWithAuth[${queryKey}]: Missing Access Token`)
      }

      return queryFn(context, accessToken)
    },
    ...queryOptions,
  })
}

import { useFeedbackAlerts } from 'modules/common/hooks/useFeedbackAlerts'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { EnumExportTransactionsType } from './useExportInternalAccountTransactionsForm'

export const useExportTransactionsSimpleFeedbackAlerts = () => {
  const { t } = useTranslation('bank-components')

  const { showErrorFeedbackAlert, showSuccessFeedbackAlert } =
    useFeedbackAlerts()

  return {
    showExportTransactionsSuccessAlert: () =>
      showSuccessFeedbackAlert({
        description: t('exportTransactionsFeedback.success', {
          context: EnumExportTransactionsType.SIMPLE,
        }),
      }),
    showExportTransactionsErrorAlert: () =>
      showErrorFeedbackAlert({
        description: t('exportTransactionsFeedback.error', {
          context: EnumExportTransactionsType.SIMPLE,
        }),
      }),
  }
}

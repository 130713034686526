import { AccountApi, ProviderApi } from '@blank/api'

export enum EnumLinxoAddWidgetResult {
  CREATED = 'created',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export enum EnumLinxoEditWidgetResult {
  EDITED = 'edited',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export type EnumLinxoWidgetResult =
  | EnumLinxoAddWidgetResult
  | EnumLinxoEditWidgetResult

export interface LinxoWidgetResponse {
  result: EnumLinxoWidgetResult
  status?: 'success'
  connectionId?: string
  queueId?: string
  channelDefinitionId?: string
  accountId?: string
  tdaeRecordSortKey?: string
}

export interface AccountWithProvider extends AccountApi {
  provider?: ProviderApi
}

export type PatchAccountPayload = Partial<
  Pick<AccountApi, 'name' | 'type' | 'connectionId'> & { queueId: string }
>

export type SyncLinxoAccountsPayload = Pick<AccountApi, 'connectionId'> & {
  queueId: string
}

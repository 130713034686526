import { Amount } from 'modules/common/components/_ui/Amount/Amount'
import { Skeleton } from 'modules/common/components/_ui/Skeletons/Skeleton/Skeleton'

interface Props {
  amount: number | undefined
}

export const BalanceAmount = ({ amount }: Props) => {
  return <Amount amount={amount} size="md" className="shrink-0" />
}

export const BalanceAmountSkeleton = () => {
  return <Skeleton className="block h-8 w-24" />
}

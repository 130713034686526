import { AccountApi } from '@blank/api'
import { UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useMutationWithAuth } from 'modules/auth/hooks/useMutationWithAuth'
import { postAdvancedTransactionsExport } from '../api/postAdvancedTransactionsExport'
import { PostAdvancedTransactionsExportPayload } from '../types'

interface Props {
  account: AccountApi
  mutationOptions?: UseMutationOptions<
    Record<string, never>,
    AxiosError,
    PostAdvancedTransactionsExportPayload
  >
}

export const usePostAdvancedTransactionsExportMutation = ({
  account,
  mutationOptions,
}: Props) =>
  useMutationWithAuth<
    Record<string, never>,
    AxiosError,
    PostAdvancedTransactionsExportPayload
  >({
    mutationFn: async ({ accessToken, ...payload }) =>
      postAdvancedTransactionsExport({
        account,
        payload,
        accessToken,
      }),
    ...mutationOptions,
  })

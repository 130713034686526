import { PaginatedList, TransactionApi } from '@blank/api'
import { InfiniteData, QueryKey } from '@tanstack/react-query'
import { useInfiniteQueryWithAuth } from 'modules/auth/hooks/useInfiniteQueryWithAuth'
import { InfiniteQueryOptions } from 'modules/auth/types/queries'
import { fetchAccountTransactions } from '../api/fetchAccountTransactions'
import { EnumBankQueryKeys } from './keys'

interface PageParam {
  pageToken: string | null
}

export const useAccountTransactionsQuery = (
  accountId: string,
  queryOptions?: Partial<
    InfiniteQueryOptions<
      PaginatedList<TransactionApi>,
      Error,
      InfiniteData<PaginatedList<TransactionApi>>,
      QueryKey,
      PageParam
    >
  >
) => {
  const { data: infiniteData, ...rest } = useInfiniteQueryWithAuth<
    PaginatedList<TransactionApi>,
    Error,
    PageParam
  >({
    queryKey: [EnumBankQueryKeys.TRANSACTIONS_LIST, accountId],
    queryFn: async ({ pageParam }, accessToken) =>
      fetchAccountTransactions({
        accountId,
        accessToken,
        pageToken: pageParam.pageToken,
      }),
    getNextPageParam: ({ nextPageToken }) => {
      if (nextPageToken) {
        return { pageToken: nextPageToken }
      }
    },
    initialPageParam: { pageToken: null },
    ...queryOptions,
  })

  const data = infiniteData?.pages.reduce<TransactionApi[]>(
    (allData, page) => [...allData, ...page.data],
    []
  )

  return {
    data,
    ...rest,
  }
}

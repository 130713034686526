import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUserQuery'
import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ExportExternalAccountTransactionsFormData } from './useExportExternalAccountTransactionsForm'

export const useExportExternalAccountTransactionsFormSetup = ({
  reset,
}: UseFormReturn<ExportExternalAccountTransactionsFormData>) => {
  const { data: authenticatedUser } = useAuthenticatedUserQuery()
  const defaultEmail = authenticatedUser?.email

  // Set default user email as receipt email
  useEffect(() => {
    if (defaultEmail) {
      reset({ email: defaultEmail }, { keepDefaultValues: true })
    }
  }, [defaultEmail, reset])
}

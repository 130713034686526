import { PaginatedList, TransactionApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

interface Props {
  resultsPerPage?: number
  dateFrom?: string
  pageToken: string | null
  accountId: string
  accessToken: string
}

export const fetchAccountTransactions = async ({
  accountId,
  accessToken,
  resultsPerPage = 20,
  dateFrom = '2015-02-02T00:00:00.000Z',
  pageToken,
}: Props) => {
  const apiUrl = `/v1/bank/accounts/${accountId}/transactions`

  const { data } = await apiClient.get<PaginatedList<TransactionApi>>(apiUrl, {
    params: {
      resultsPerPage,
      dateFrom,
      ...(pageToken ? { pageToken } : {}),
    },
    headers: getAuthorizationHeader(accessToken),
  })

  return data
}

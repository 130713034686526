import dayjs from 'dayjs'
import { capitalize } from 'lodash'

interface MonthOption {
  label: string
  value: string
}

export const getMonthOptions = (
  accountCreatedAt: string,
  monthBeforeNb = 0
) => {
  const options: MonthOption[] = []
  let exportDate = dayjs(accountCreatedAt)
    .subtract(monthBeforeNb, 'month')
    .startOf('month')

  const todayDate = dayjs()
  while (exportDate.isBefore(todayDate)) {
    options.unshift({
      label: capitalize(dayjs(exportDate).format('MMMM YYYY')),
      value: exportDate.toISOString(),
    })
    // set date to the next month
    exportDate = exportDate.add(1, 'month')
  }
  return options
}

import {
  FeedbackAlertContext,
  useFeedbackAlertContextDispatch,
} from 'modules/common/contexts/FeedbackAlertContext'
import { useCallback } from 'react'

type Payload = Omit<FeedbackAlertContext, 'type'>

export const useFeedbackAlerts = () => {
  const { showAlert } = useFeedbackAlertContextDispatch()

  const showSuccessFeedbackAlert = useCallback(
    (payload: Payload) =>
      showAlert({
        type: 'success',
        ...payload,
      }),
    [showAlert]
  )

  const showErrorFeedbackAlert = useCallback(
    (payload: Payload) =>
      showAlert({
        type: 'error',
        ...payload,
      }),
    [showAlert]
  )

  return {
    showSuccessFeedbackAlert,
    showErrorFeedbackAlert,
  }
}

import { mergeClasses } from '@blank/utilities'
import { PropsWithChildren } from 'react'

export interface BannerProps {
  type?: 'default' | 'important' | 'danger' | 'warning'
  className?: string
}

export const Banner = ({
  className,
  type = 'default',
  children,
}: PropsWithChildren<BannerProps>) => {
  return (
    <div
      className={mergeClasses(
        'flex border-b p-2 text-info-500',
        // We need to explicit all the potential classes to prevent purge
        // https://github.com/nuxt-community/tailwindcss-module/issues/55
        {
          'border-b-info-200 bg-info-90': type === 'default',
          'border-b-primary-200 bg-primary-90': type === 'important',
          'border-b-warning-200 bg-warning-90': type === 'warning',
          'border-b-danger-200 bg-danger-90': type === 'danger',
        },
        className
      )}
    >
      {children}
    </div>
  )
}

import dayjs from 'dayjs'
import { ExportInternalAccountTransactionsFormData } from 'modules/bank/hooks/transaction/useExportInternalAccountTransactionsForm'
import { HelperText } from 'modules/common/components/_ui/HelperText/HelperText'
import { VerticalProgressStep } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStep/VerticalProgressStep'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { ComponentProps, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  TransactionMonthOption,
  TransactionMonthSelect,
} from '../../TransactionMonthSelect/TransactionMonthSelect'

interface Props
  extends Pick<ComponentProps<typeof VerticalProgressStep>, 'status'> {
  isAdvancedExport: boolean
  monthOptions: TransactionMonthOption[]
}

export const ExportInternalAccountTransactionsMonthStep = ({
  isAdvancedExport,
  monthOptions,
  status,
}: Props) => {
  const { t } = useTranslation('bank-components')

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<ExportInternalAccountTransactionsFormData>()

  const selectedMonth = watch('month')

  const endMonthOptions = useMemo(
    () =>
      monthOptions.filter(
        (option) =>
          dayjs(option.value).isSame(selectedMonth?.value) ||
          dayjs(option.value).isAfter(selectedMonth?.value)
      ),
    [monthOptions, selectedMonth?.value]
  )

  return (
    <VerticalProgressStep
      status={status}
      title={t('exportInternalAccountTransactionsForm.monthTitle')}
    >
      <div className="mb-3">
        <div className="flex gap-2">
          <Controller
            name="month"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TransactionMonthSelect
                className="flex-1"
                onChange={(newValue) => newValue && onChange(newValue)}
                onBlur={onBlur}
                value={value}
                options={monthOptions}
                menuPlacement="top"
                placeholderTitle={
                  isAdvancedExport
                    ? t('transactionMonthSelect.placeholderAdvancedFrom')
                    : t('transactionMonthSelect.placeholderSimple')
                }
              />
            )}
          />
          {isAdvancedExport && (
            <Controller
              name="endMonth"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TransactionMonthSelect
                  className="flex-1"
                  onChange={(newValue) => newValue && onChange(newValue)}
                  onBlur={onBlur}
                  value={value}
                  options={endMonthOptions}
                  menuPlacement="top"
                  placeholderTitle={t(
                    'transactionMonthSelect.placeholderAdvancedTo'
                  )}
                  noOptionsMessage={() =>
                    t('transactionMonthSelect.noOptionMessage')
                  }
                />
              )}
            />
          )}
        </div>
        {errors.endMonth?.value?.message && (
          <HelperText color="danger" text={errors.endMonth.value.message} />
        )}
      </div>
    </VerticalProgressStep>
  )
}

import { AxiosError } from 'axios'
import {
  LazyQueryOptions,
  useLazyQueryWithAuth,
} from 'modules/auth/hooks/useLazyQueryWithAuth'
import { EnumBankQueryKeys } from 'modules/bank/queries/keys'
import { ApiError } from 'modules/common/types/error'
import { fetchMonthTransactionsBlob } from '../api/fetchMonthTransactionsBlob'

interface Props {
  accountId: string
  monthDate: string | undefined
  queryOptions?: LazyQueryOptions<Blob, AxiosError<ApiError>>
}

export const useMonthTransactionsBlobLazyQuery = ({
  accountId,
  monthDate,
  queryOptions,
}: Props) => {
  return useLazyQueryWithAuth<Blob, AxiosError<ApiError>>({
    queryKey: [EnumBankQueryKeys.MONTH_TRANSACTIONS_BLOB, accountId, monthDate],
    queryFn: async (_context, accessToken) => {
      if (!monthDate) {
        throw new Error(
          'useMonthTransactionsBlobLazyQuery: Missing parameter monthDate'
        )
      }

      return fetchMonthTransactionsBlob({
        accessToken,
        accountId,
        monthDate,
      })
    },
    ...queryOptions,
  })
}

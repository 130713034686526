import {
  EnumAttachmentType,
  EnumTransactionDebitCategory,
  EnumTurnoverType,
  TransactionAmountApi,
  TransactionInformationApi,
  TransactionVATApi,
} from '@blank/api'

export type TransactionCategory =
  | EnumTransactionDebitCategory
  | EnumTurnoverType

export type PutTransactionInformationPayload = Pick<
  TransactionInformationApi,
  | 'vat'
  | 'type'
  | 'note'
  | 'date'
  | 'isTurnover'
  | 'turnoverType'
  | 'isProfessional'
  | 'debitCategory'
> & { amount: Omit<TransactionAmountApi, 'ht'> }

export type TransactionTypeToggleKeys = keyof Pick<
  TransactionInformationApi,
  'isProfessional' | 'isTurnover'
>

export enum EnumTransactionInformationError {
  TRANSACTION_COMPLEMENTARY_INFORMATION_NOT_FOUND = 'transaction_complementary_information_not_found',
}

export interface PostTransactionAttachmentPayload {
  file: File
}

export interface PostAdvancedTransactionsExportPayload {
  date: string
  endDate?: string
  email: string
}

export interface PatchValidateTransactionAttachmentPayload {
  attachmentType?: EnumAttachmentType
  category?: EnumTransactionDebitCategory
  vat?: TransactionVATApi[]
}

export interface LinkInvoicingDocumentToTransactionPayload {
  documentId: string
}

import { CardLimitApi, EnumCardStatusCode } from '@blank/api'

export interface PatchCardLimitsPayload extends CardLimitApi {
  cardId: string
}

export interface PutCardStatusPayload {
  status: EnumCardStatusCode
}

export enum EnumCardType {
  BLACKCARD = 'VISA_BUSINESS_DEBIT_NOIRE',
  WHITECARD = 'VISA_BUSINESS_DEBIT_BLANCHE',
}

export enum EnumCardStatusPriority {
  ACT = 1,
  BLO,
  PRE,
  CAN,
  LOS,
}

export interface PutActivateCardPayload {
  cardLastFourDigits: string
}
